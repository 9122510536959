/*Специализация*/

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Bold.eot');
    src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
        url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro-Bold.woff2') format('woff2'),
        url('../fonts/GothamPro-Bold.woff') format('woff'),
        url('../fonts/GothamPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Medium.eot');
    src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
        url('../fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro-Medium.woff2') format('woff2'),
        url('../fonts/GothamPro-Medium.woff') format('woff'),
        url('../fonts/GothamPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro.eot');
    src: local('Gotham Pro'), local('GothamPro'),
        url('../fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro.woff2') format('woff2'),
        url('../fonts/GothamPro.woff') format('woff'),
        url('../fonts/GothamPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

/* font-family: 'Source Sans Pro'; */

body {
    font-family: 'Gotham Pro';
    font-size: 16px;
    color: #333333;
}

* {
    box-sizing: border-box;
}


.slick-slide:focus,
button:focus,
input:focus {
    outline: 0;
}

button {
    cursor: pointer;
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%
}

.d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
}

h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
}

.button {
    background: #29ABE2;
    border-radius: 32px;
    width: 301px;
    height: 64px;
    filter: drop-shadow(0px 0px 20px #29ABE2);
    border: none;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0;
    text-transform: uppercase;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 0px 20px #29ABE2;
    }
}

a.button,
div.button {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
}

.cap__button {
    background: none;
    border: 5px solid #29ABE2;
    text-transform: none;
    transition: all 0.5s ease;

    &:hover {
        background: #29ABE2;
        ;
    }
}

.first__button {
    margin-left: auto;
    margin-right: auto;
}

.container {
    width: 1170px;
    max-width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

.header {
    background: #224356 url(../img/first.png) no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
}

.header--2 {
    background: #224356;
}

.cap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
}

.cap__left {
    display: flex;
    align-items: center;
}

.cap__right {
    display: flex;
}

.cap__left_text {
    font-size: 12px;
    line-height: 16px;
    margin-left: 24px;
}

.cap__link {
    display: inline-block;
    border-bottom: 2px solid #29ABE2;
    padding-bottom: 3px;
    cursor: pointer;
}

.cap__phone {
    display: block;
    font-weight: bold;
    margin: 4px 0 3px;
}

.cap__button {
    width: 170px;
    margin-left: 48px;
    margin-top: 9px;
}

.cap__right_item {
    line-height: 24px;
    background: url(../img/folder.svg) no-repeat left top;
    padding-left: 30px;
}

.cap__right_item--2 {
    background-image: url(../img/phone.svg);
    margin-left: 37px;
}

.cap__left {
    padding: 15px 74px 15px 0;
    border-right: 2px solid #29ABE2;
}

.first__label {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 16px;
}

.first {
    padding-top: 51px;
    padding-bottom: 82px;
}

.first__sub-title {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    padding-top: 31px;
    margin-top: 25px;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 294px;
        height: 2px;
        background: #29ABE2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.first__button{
    width: 411px;
    display: block;
    margin: 48px auto 0;
    font-size: 18px;
    text-transform: none;
}

.first__box {
    display: flex;
    justify-content: center;
    font-size: 18px;
    line-height: 24px;
    margin-top: 48px;
    & > :last-child {
        margin-right: 0;
    }
}

.first__item {
    margin-right: 40px;
}

.manual {
    background: url(../img/manual-bg.png) no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 57px 0 62px 0;
}

.manual__box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
}

.manual__item {
    background: url(../img/manual.png) no-repeat center;
    width: 170px;
    min-height: 229px;
    padding: 24px 16px;
    position: relative;

    &> :before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: -32px;
        margin: auto;
        display: block;
        width: 2px;
        height: 32px;
        background: #29ABE2;
        box-shadow: 0px 0px 10px #29ABE2;
        border-radius: 5px;
    }
}

.manual__text {
    font-size: 13px;
    line-height: 16px;
    margin-top: 27px;
}

.manual__title {
    color: #fff;
}

.manual__nums {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 32px;
    margin-top: 49px;

    &:after {
        content: '';
        display: block;
        box-shadow: 0px 0px 10px #29ABE2;
        background: #29ABE2;
        border-radius: 5px;
        width: 972px;
        height: 2px;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
    }
}

.manual__nums_item {
    width: 170px;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    color: #29ABE2;
    text-shadow: 0px 0px 10px #29ABE2;
    font-weight: bold;
    font-family: 'Source Sans Pro';
    padding-bottom: 14px;
}

.manual__bottom_text {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 58px 0 32px 0;
    color: #fff;
}

.manual__button {
    margin: 0 auto;
}

.video__item {
    position: relative;
    max-width: 380px;
    min-width: 380px;
    height: 245px;
}

.video__block {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.play {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.video__box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
}

.video__title {
    text-align: left;
    font-weight: bold;
    margin-top: 62px;
}

.video__slider {
    max-width: 810px;
    margin-right: -24px;
}

.slider__arrow {
    width: 40px;
    height: 40px;
    background: #29ABE2 url(../img/arrow.svg) no-repeat center;
    border-radius: 50%;
    border: none;
    position: relative;
    z-index: 2;
    transition: all 0.5s ease;

    &:hover {
        box-shadow: 0px 0px 20px #29ABE2;
    }
}

.slide__next {
    transform: rotate(180deg);
}

.slider__controls {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    margin-top: 31px;
}

.slick-dots {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    margin-left: -24px;
    z-index: 1;
    bottom: -57px;

    button {
        width: 10px;
        height: 10px;
        background: #29ABE2;
        opacity: 0.3;
        font-size: 0;
        padding: 0;
        border-radius: 50%;
        border: none;
        transition: all 0.5s ease;
        margin: 0 12px;
    }

    .slick-active {
        button {
            opacity: 1;
        }
    }
}

.video {
    padding: 56px 0;
    background: #EAF7FC;
}

.debts__item {
    width: 461px;
    height: 80px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    border: 3px solid #29ABE2;
    box-shadow: 0px 0px 20px #29ABE2;
    border-radius: 5px;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    padding-left: 80px;
    background: url(../img/check.svg) no-repeat 21px 19px;
    margin: 24px 12px 0 12px;
}

.debts {
    background: url(../img/manual-bg.png) no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    padding: 57px 0 76px 0;
}

.debts__title {
    color: #fff;
}

.debts__box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 27px;
}

.debts__buton {
    margin: 44px auto 0 auto;
    display: block;
}

.cases__item {
    width: 220px;
    max-width: 100%;

    img {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }
}

.cases__slide {
    padding: 0 20px;
}

.cases__text {
    font-size: 13px;
    line-height: 16px;
    color: #333333;
    margin-top: 17px;

    span {
        color: #29ABE2;
        font-weight: bold;
    }
}

.cases {
    padding: 49px 0 108px 0;

    .slider__arrow {
        top: 133px;
        left: -17px;
        position: absolute;
    }

    .slider__next_2 {
        transform: rotate(180deg);
        right: -11px;
        left: auto;
    }
}

.cases__box {
    position: relative;
    margin-top: 55px;
}

.cases__slider {
    position: relative;
    left: 9px;
}

.cases__caption {
    font-size: 15px;
    line-height: 24px;
}

.about__text {
    font-size: 24px;
    line-height: 32px;
    color: #000000;
}

.about__box {
    max-width: 655px;
}

.about__button {
    width: 442px;
    height: 56px;
    margin: 55px 0 129px 0;
}

.about__name {
    font-size: 24px;
    line-height: 32px;
    color: #000000;
}

.about__position {
    line-height: 32px;
    color: #828282;
}

.about {
    padding: 49px 0;
    position: relative;
    overflow-x: hidden;
}

.about__img {
    position: absolute;
    right: 0px;
    bottom: 0;
}

.parners__item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    max-width: 364px;
    margin-bottom: 48px;
}

.general-bg {
    background: linear-gradient(180deg, rgba(41, 171, 226, 0.1) 58.37%, rgba(242, 242, 242, 0) 100%);
}

.partners {
    padding: 73px 0 107px 0;
}

.partners__caption {
    line-height: 24px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 8px;
}

.partners__text {
    line-height: 24px;
}

.partners__content {
    margin-left: 16px;
}

.parners__box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 98px;
}

.partners__img {
    min-width: 97px;
}

.parners__title {
    span {
        font-size: 18px;
        line-height: 40px;
        display: block;
    }
}

.about__top {
    width: 312px;
    height: 312px;
    background: #29ABE2;
    opacity: 0.1;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: -50px;
}

.team__item {
    max-width: 500px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.team__img-box {
    max-height: 560px;
}

.team__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, .7);
    color: #fff;
    padding: 24px;
}

.team__name {
    font-size: 22px;
    font-weight: bold;
}

.team__position {
    font-size: 18px;
    margin-top: 10px;
}

.team__box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin-top: 40px;
}

.team__title {
    text-transform: none;
}

.team {
    padding-bottom: 107px;
}

.social {
    background: url(../img/social.png) no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 46px 0 49px 0;
}

.social__box {
    max-width: 451px;
    margin-left: auto;
    margin-right: 97px;
}

.social__item {
    width: 448px;
    height: 80px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    padding-left: 86px;
    margin-top: 32px;
    transition: all 0.5s ease;
}

.social__item--1 {
    background: #219653 url(../img/soc1.svg) no-repeat 19px center;

    &:hover {
        box-shadow: 0px 0px 20px #219653;
    }
}

.social__item--2 {
    background: #9B51E0 url(../img/soc2.svg) no-repeat 19px center;

    &:hover {
        box-shadow: 0px 0px 20px #9B51E0;
    }
}

.social__item--3 {
    background: #2D9CDB url(../img/soc3.svg) no-repeat 19px center;

    &:hover {
        box-shadow: 0px 0px 20px #2D9CDB;
    }
}

.social__title {
    color: #FFFFFF;
    text-transform: none;

    strong {
        color: #29ABE2;
        font-weight: 500;
    }
}

.social__wrapper {
    margin-top: 44px;
}

.social2__item {
    width: 32px;
    height: 32px;
    display: block;
    background-color: #29ABE2;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: all 0.5s ease;
}

.social2__item--1 {
    background-image: url(../img/soc4.png);
    margin-right: 48px;

    &:hover {
        box-shadow: 0px 0px 20px #2D9CDB;
    }
}

.social2__item--2 {
    background-image: url(../img/soc5.png);

    &:hover {
        box-shadow: 0px 0px 20px #2D9CDB;
    }
}

.social__bottom {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-top: 54px;
}

.questions {
    padding: 52px 0 66px;
}

.acc-body {
    display: none;
}

.acc-head {
    padding: 11px 24px 13px 24px;
    background: rgba(41, 171, 226, 0.1);
    line-height: 32px;
    position: relative;
    cursor: pointer;

    &:after {
        content: '+';
        display: block;
        font-weight: 300;
        font-size: 40px;
        color: #29ABE2;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 16px;
        margin: auto;
        max-width: 35px;
        max-height: 32px;
        transition: all 0.5s ease;
    }

    &.active {
        &:after {
            transform: rotate(135deg);
        }
    }
}

.acc-body {
    padding: 11px 24px 13px 24px;
    background: rgba(41, 171, 226, 0.1);
    line-height: 24px;
    margin-top: 16px;
}

.acc-item {
    margin-top: 16px;
}

.accordeon {
    margin-top: 20px;
}

.order {
    background: url(../img/manual-bg.png) no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    color: #fff;
    padding: 76px 0;
}

.order__title {
    text-align: left;
    color: #fff;
    font-weight: normal;
}

.order__bottom {
    line-height: 24px;
    margin-top: 55px;

    a {
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        display: block;
    }
}

.order__right {
    width: 364px;
    background: #FFFFFF;
    border: 2px solid #29ABE2;
    box-shadow: 0px 0px 10px #29ABE2;
    border-radius: 5px;
    padding: 34px 40px 33px 40px;
}

.input {
    width: 282px;
    height: 53px;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid #E0E0E0;
    margin-bottom: 32px;
    transition: all 0.5s ease;

    &:focus {
        border-bottom-color: #29ABE2;
    }
}

.consent {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #828282;
    margin-top: 27px;

    strong {
        color: #29ABE2;
        font-weight: 500;
    }
}

.order__button {
    margin-top: 25px;
}

.order__box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
    padding-right: 100px;
}

.contacts__item {
    display: block;
}

.contacts__item.phone {
    margin-left: 0;
    padding-bottom: 10px;
    padding-top: 5px;
    margin-bottom: 18px;
}

.contacts__box {
    width: 490px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 68px 56px 54px 56px;
    position: relative;
    z-index: 2;
}

.contacts__wrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;

    .social__bottom {
        display: block;
        margin-top: 0;
    }

    .social2__item--1 {
        margin-right: 0;
        margin-bottom: 25px;
    }
}

.contatcs__info {
    font-size: 14px;
    line-height: 24px;
    color: #BDBDBD;
    margin-top: 56px;
}

.contacts__title {
    text-align: left;
    margin-bottom: 52px;
}

.contacts {
    position: relative;
    padding: 98px 0;
}

.map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.footer {
    text-align: center;
    background: #29ABE2;
    padding: 37px 0 28px 0;
}

.footer__text {
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    text-align: center;
    margin-top: 10px;

    a {
        text-decoration-line: underline;
        display: block;
    }
}


/*Квиз*/

.radio input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
}

.radio__text {
    position: relative;
    padding: 0 0 0 33px;
    cursor: pointer;
    font-size: 16px;
    line-height: 130%;
    color: #333333;
    margin-bottom: 19px;
}

.radio__text:before {
    content: '';
    position: absolute;
    display: block;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    opacity: 0.5;
    border: 1px solid #828282;
    border-radius: 50%;
}

.radio__text:after {
    content: '';
    position: absolute;
    top: 7px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 0;
    transition: .2s;
    background: #29ABE2;
}

.radio input:checked+.radio__text:after {
    opacity: 1;
}

.radio input:checked+.radio__text:before {
    border-color: #29ABE2;
}

.quiz {
    background: url(../img/quiz.png) no-repeat center top;
    padding-top: 24px;
    padding-bottom: 46px;
    color: #000;
    min-height: 718px;

    .container {
        position: relative;
    }
}

.quiz__slide {
    display: none;
}

.quiz__slide--1 {
    display: block;
}

.quiz__box {
    padding: 41px 65px 56px 65px;
    width: 100%;
    min-height: 600px;
}

.quiz__title {
    text-align: left;
}

.quiz__nums {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-top: 35px;

    & :first-child {
        &:before {
            display: none;
        }
    }

    li {
        width: 32px;
        height: 32px;
        background: #DFDFDF;
        border-radius: 50%;
        text-align: center;
        padding-top: 7px;
        margin-right: 64px;
        position: relative;
        z-index: 2;
        font-weight: bold;
        color: #828282;

        &:before {
            content: '';
            display: block;
            width: 64px;
            height: 2px;
            border-bottom: 2px dashed #DFDFDF;
            position: absolute;
            left: -64px;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;

        }
    }

    li.active {
        border: 3px solid #29ABE2;
        color: #29ABE2;
        background-color: transparent;
        padding-top: 4px;

        &:before {
            border-bottom: 2px dashed #29ABE2;
        }
    }
}

.quiz__caption {
    font-weight: bold;
    line-height: 24px;
    margin: 46px 0 30px 0;
}

.slide__next {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.quiz__slide--1 {
    .quiz__caption {
        margin-bottom: 54px;
    }

    .slider__controls {
        margin-top: 76px;
    }
}

.quiz__slide--4 {
    .quiz__caption {
        margin-bottom: 30px;
    }

    .slider__controls {
        margin-top: 53px;
    }
}



.quiz__slide--5 {
    .quiz__caption {
        margin: 86px 0 39px 0;
    }

    .slider__controls {
        margin-top: 95px;
    }
}

.quiz__input {
    width: 364px;
    height: 56px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 24px;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
}

.slider__controls--quiz .slide__prev {
    background: #4F4F4F;
    margin-right: 16px;
    cursor: pointer;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: center;
}

.slider__controls--quiz {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-top: 59px;
    max-width: 650px;
}

.quiz__prev {
    background: transparent;
    border: 3px solid #29ABE2;
    color: #29ABE2;
}

.reviews__head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    -ms-align-items: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid rgba(195, 195, 197, 0.7);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 28px 24px;
    position: relative;
    z-index: 2;
}

.reviews__item {
    max-width: 530px;
    width: 100%;
    position: relative;
    margin-left: 160px;
}

.reviews__body {
    background: #29ABE2;
    border: 1px solid rgba(195, 195, 197, 0.7);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 31px 24px;
    color: #fff;
    line-height: 24px;
    margin-top: -3px;
    position: relative;
    z-index: 1;
}

.reviews__slide {
    padding-bottom: 114px;
}

.reviews__name {
    font-size: 18px;
    line-height: 24px;
}

.reviews__date {
    font-size: 14px;
    line-height: 24px;
    color: #828282;
}

.reviews__treeangle {
    position: absolute;
    bottom: -73px;
    right: 0px;
}

.reviews__right {
    position: absolute;
    right: -290px;
    top: 48px;
}

.reviews__written {
    position: absolute;
    bottom: -25px;
    right: -25px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    padding: 13px 10px 16px 10px;
    width: 194px;
    z-index: 3;
}

.reviews__slider {
    margin-top: 35px;
}

.reviews__written_label {
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    opacity: 0.2;
}

.reviews__written_num {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    color: #29ABE2;
}

.reviews__stars {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    img {
        margin: 0 2px;
    }
}

.reviews__controls {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin: 40px 0 0 170px;
}

.slide__next_3 {
    transform: rotate(180deg);
    margin-left: 86px;
}

.reviews {
    .container {
        position: relative;
    }
}

.reviews {
    padding-bottom: 68px;
}

.modal__title {
    font-size: 24px;
    text-align: center;
    margin: 20px 0;
}

.arcticmodal-close {
    font-size: 25px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.order__right {
    position: relative;
}

.fancybox-content {
    background: #fff !important;
}

.order__right--2 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.quiz__title--2 {
    font-size: 22px;
    margin-top: 40px;
    color: #29ABE2;
}

.politica {
    padding: 60px 0 40px 0;
    line-height: 24px;

    .row {
        margin-bottom: 30px;
    }

    h5 {
        margin-bottom: 10px;
        font-size: 20px;
    }

    h4 {
        font-size: 22px;
    }
}